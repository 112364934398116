.rss-config {
  display: flex;
  background-color: var(--card-background);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.config-sidebar {
  width: 200px;
  padding: 20px;
  border-right: 1px solid var(--border-color);
}

.config-sidebar h2 {
  margin-bottom: 20px;
  color: var(--primary-color);
}

.config-sidebar button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  text-align: left;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  color: var(--text-color);
  cursor: pointer;
  transition: background-color 0.3s;
}

.config-sidebar button.active,
.config-sidebar button:hover {
  background-color: var(--primary-color);
  color: var(--button-text-color);
}

.config-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  max-height: 100vh;
}

.config-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.config-actions button {
  padding: 8px 15px;
  background-color: var(--primary-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.config-actions button:hover {
  background-color: var(--secondary-color);
}

.source-list, .category-list {
  list-style-type: none;
  padding: 0;
}

.source-item, .category-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid var(--border-color);
  transition: background-color 0.3s;
}

.source-item:hover, .category-item:hover {
  background-color: var(--card-header-background-light);
}

.source-info, .category-name {
  flex: 1;
}

.source-name {
  font-weight: bold;
  color: var(--primary-color);
}

.source-category {
  font-size: 0.9em;
  color: var(--category-color);
}

.source-url {
  font-size: 0.8em;
  color: var(--text-color);
  opacity: 0.7;
}

.source-description {
  font-size: 0.9em;
  margin-top: 5px;
  color: var(--text-color);
  opacity: 0.8;
}

.source-actions, .category-actions {
  display: flex;
  gap: 10px;
  align-items: center;
}

.source-status {
  padding: 3px 8px;
  border-radius: 12px;
  font-size: 0.8em;
}

.source-status.ok {
  background-color: #4CAF50;
  color: white;
}

.source-status.error {
  background-color: #f44336;
  color: white;
}

.source-status.loading {
  background-color: #FFA500;
  color: white;
}

.edit-button, .delete-button, .refresh-button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.edit-button {
  background-color: #4CAF50;
  color: white;
}

.delete-button {
  background-color: #f44336;
  color: white;
}

.refresh-button {
  background-color: #2196F3;
  color: white;
}

.category-add {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.category-add input {
  flex: 1;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.category-add button {
  padding: 8px 15px;
  background-color: var(--primary-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.category-add button:hover {
  background-color: var(--secondary-color);
}

/* ... 其他样式保持不变 ... */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: var(--card-background);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
}

.source-modal h3, .delete-confirm-modal h3 {
  color: var(--primary-color);
  margin-bottom: 20px;
}

.source-modal form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.source-modal input, .source-modal select, .source-modal textarea {
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 14px;
}

.source-modal textarea {
  min-height: 100px;
  resize: vertical;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.modal-buttons button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-buttons button[type="submit"] {
  background-color: var(--primary-color);
  color: var(--button-text-color);
}

.modal-buttons button[type="button"] {
  background-color: var(--secondary-color);
  color: var(--button-text-color);
}

.delete-confirm-modal {
  text-align: center;
}

.delete-confirm-modal p {
  margin-bottom: 20px;
}

.confirm-delete {
  background-color: #f44336;
}

.cancel-delete {
  background-color: var(--secondary-color);
}

.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--primary-color);
  color: var(--button-text-color);
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 1; }
}

/* ... 其他样式保持不变 ... */

.category-list {
  list-style-type: none;
  padding: 0;
}

.category-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 8px;
  background-color: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  transition: background-color 0.3s;
}

.category-item:hover {
  background-color: var(--card-header-background-light);
}

.category-item.dragging {
  opacity: 0.5;
}

.save-categories-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
  font-size: 16px;
  font-weight: bold;
}

.save-categories-button:hover {
  background-color: var(--secondary-color);
}

.save-categories-button:active {
  transform: scale(0.98);
}

/* ... 其他样式保持不变 ... */

.category-item {
  user-select: none;
  padding: 16px;
  margin: 0 0 8px 0;
  background-color: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.category-item:hover {
  background-color: var(--card-header-background-light);
}

.category-item.dragging {
  background-color: var(--primary-color);
  color: var(--button-text-color);
}