/* 基础样式 */
:root {
  --transition-speed: 0.3s;
}

body {
  background-color: var(--backgroundColor);
  color: var(--textColor);
  font-family: Arial, sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.app {
  max-width: 1500px;
  margin: 0 auto;
  padding: 20px;
}

.main-content {
  display: flex;
  gap: 20px;
}

.category-nav {
  width: 200px;
  position: sticky;
  top: 20px;
  align-self: flex-start;
}

.content-area {
  flex: 1;
  min-width: 0;
}

.category-nav button:hover {
  background-color: var(--navButtonHoverBackground);
}

.category-nav button.active {
  background-color: var(--navButtonActiveBackground);
}

.rss-container,
.category-feeds {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  width: 100%;
}

.rss-card {
  flex: 0 0 calc(33.33% - 80px);
  max-width: calc(33.33% - 80px);
  background-color: var(--cardBackground);
  border-radius: 8px;
  box-shadow: 0 4px 6px var(--shadowColor);
  height: auto;
  max-height: 550px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--borderColor);
  position: relative;
  overflow: hidden;
}

.card-spacer {
  width: 100%;
  height: 20px;
}

.rss-card-header {
  background-color: var(--cardHeaderBackgroundLight);
  padding: 10px 15px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid var(--borderColor);
}

.rss-card-title-category {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.rss-card-header h2 {
  margin: 0;
  color: var(--primaryColor);
  font-size: 1.1em;
  flex: 1;
}

.rss-card-category {
  color: var(--textColor);
  font-size: 0.8em;
  background-color: var(--cardHeaderBackground);
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 10px;
  border: 1px solid var(--borderColor);
}

.rss-card-description {
  font-size: 0.8em;
  color: var(--textColor);
  opacity: 0.8;
  margin-top: 5px;
}

.rss-card-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px 15px;
}

.rss-card-content::-webkit-scrollbar {
  display: none;
}

.rss-card ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.rss-card li {
  margin-bottom: 12px;
  padding: 5px 0;
  border-bottom: 1px solid var(--borderColor);
  font-size: 15px;
  line-height: 1.5;
}

.rss-card a {
  color: var(--textColor);
  text-decoration: none;
  font-weight: normal;
  display: inline;
  word-wrap: break-word;
  word-break: break-word;
  transition: color 0.2s ease;
}

.rss-card a:hover {
  color: var(--primaryColor);
}

.rss-card .item-date {
  font-size: 0.9em;
  color: var(--updateTimeColor);
  white-space: nowrap;
  transition: color 0.2s ease;
}

.rss-card-footer {
  font-size: 0.8em;
  color: var(--updateTimeColor);
  text-align: right;
  padding: 10px 15px;
  border-top: 1px solid var(--borderColor);
}

.app-header {
  text-align: center;
  margin-bottom: 30px;
  padding: 20px 0;
  color: var(--headerTextColor);
}

.app-header h1 {
  font-size: 2.75em;
  margin-bottom: 10px;
}

.app-header h2 {
  font-size: 1.2em;
  font-weight: normal;
  opacity: 0.8;
  margin-bottom: 20px;
}

.category-nav-inner {
  background-color: var(--cardBackground);
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px var(--shadowColor);
}

.category-nav button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  color: var(--textColor);
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.category-nav button:hover,
.category-nav button.active {
  background-color: var(--primaryColor);
  color: var(--buttonTextColor);
}

.theme-toggle,
.view-mode-toggle,
.skin-toggle {
  font-size: 1em;
  padding: 10px 20px;
  background-color: var(--secondaryColor);
  color: var(--buttonTextColor);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.theme-toggle:hover,
.view-mode-toggle:hover,
.skin-toggle:hover {
  background-color: var(--primaryColor);
}

.app-footer {
  margin-top: 30px;
  padding: 20px 0;
  background-color: var(--footerBackground);
  text-align: center;
}

.config-link {
  padding: 10px 20px;
  background-color: var(--primaryColor);
  color: var(--buttonTextColor);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.config-link:hover {
  background-color: var(--secondaryColor);
}

.copyright {
  margin-top: 10px;
  font-size: 0.9em;
  color: var(--textColor);
}

@media (max-width: 1200px) {
  .rss-card {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
}

@media (max-width: 768px) {
  .rss-card {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.password-form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.password-input {
  padding: 8px;
  border: 1px solid var(--borderColor);
  border-radius: 4px;
  background-color: var(--backgroundColor);
  color: var(--textColor);
}

.password-submit {
  padding: 8px 15px;
  background-color: var(--primaryColor);
  color: var(--buttonTextColor);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.password-submit:hover {
  background-color: var(--secondaryColor);
}

.rss-card.error {
  border-color: #ff4d4d;
}

.rss-card.error .rss-card-header {
  background-color: #ffeeee;
}

.category-header {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: var(--cardHeaderBackground);
  color: var(--primaryColor);
  border-radius: 4px;
}

.scroll-buttons {
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.scroll-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--primaryColor);
  color: var(--buttonTextColor);
  border: none;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.scroll-button:hover {
  background-color: var(--secondaryColor);
}

.config-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.config-tabs button {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  background-color: var(--cardBackground);
  color: var(--textColor);
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.config-tabs button.active {
  background-color: var(--primaryColor);
  color: var(--buttonTextColor);
}

.category-actions select {
  padding: 5px;
  margin-right: 10px;
  background-color: var(--cardBackground);
  color: var(--textColor);
  border: 1px solid var(--borderColor);
  border-radius: 4px;
}

.header-controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.mixed-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 600px;
  padding: 20px;
}

.mixed-item {
  background-color: var(--cardBackground);
  border-radius: 8px;
  box-shadow: 0 2px 4px var(--shadowColor);
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.mixed-item-header {
  margin-bottom: 15px;
}

.mixed-item h3 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
}

.mixed-item a {
  color: var(--primaryColor);
  text-decoration: none;
  transition: color 0.3s ease;
}

.mixed-item a:hover {
  text-decoration: underline;
}

.mixed-item a.visited {
  color: var(--textColor);
  opacity: 0.7;
}

.mixed-item-meta {
  font-size: 0.9em;
  color: var(--textColor);
  opacity: 0.7;
  margin-bottom: 10px;
}

.mixed-item-description {
  font-size: 1em;
  color: var(--textColor);
  margin-bottom: 15px;
  line-height: 1.5;
}

.read-more {
  display: inline-block;
  margin-left: 5px;
  color: var(--primaryColor);
  font-weight: bold;
}

.image-grid {
  display: grid;
  gap: 4px;
  margin-top: 15px;
}

.image-grid-small {
  grid-template-columns: repeat(3, 1fr);
}

.image-grid-large {
  grid-template-columns: repeat(3, 1fr);
}

.image-grid-item {
  aspect-ratio: 1;
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;
}

.image-grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-grid-item:hover img {
  transform: scale(1.05);
}

.image-preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-preview {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.load-more-button {
  padding: 10px 20px;
  background-color: var(--primaryColor);
  color: var(--buttonTextColor);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.load-more-button:hover {
  background-color: var(--secondaryColor);
}

.load-more-button:disabled {
  background-color: var(--borderColor);
  cursor: not-allowed;
}

.mixed-content-container {
  display: flex;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.mixed-content-container .category-nav {
  width: 150px;
  position: sticky;
  top: 20px;
  align-self: flex-start;
}

.mixed-content-container .mixed-content {
  flex: 1;
  min-width: 0;
  max-width: 800px;
}

/* 皮肤选择器样式 */
.skin-selector-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.skin-selector {
  background-color: var(--cardBackground);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 20px var(--shadowColor);
  max-width: 400px;
  width: 90%;
}

.skin-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.skin-option {
  padding: 10px;
  font-size: 0.9em;
}

.skin-icon {
  font-size: 1.5em;
}

.skin-description {
  display: none;
}

/* 调整一些全局样式以适应主题 */
body {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.app-header h1,
.app-header h2 {
  transition: color 0.3s ease;
}

.rss-card,
.mixed-item {
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.category-nav button:hover,
.category-nav button.active,
.config-actions button:hover,
.theme-toggle:hover,
.view-mode-toggle:hover,
.skin-toggle:hover {
  opacity: 0.9;
}

/* 优化按钮样式 */
button {
  transition: background-color 0.3s ease, color 0.3s ease, opacity 0.3s ease;
}

/* 优化链接样式 */
a {
  transition: color 0.3s ease;
}

.scroll-button.top {
  padding-bottom: 3px;
}

.scroll-button.bottom {
  padding-top: 3px;
}

/* 移动端适配 */
@media (max-width: 768px) {
  .app {
    padding: 10px;
  }

  .main-content {
    flex-direction: column;
  }

  .category-nav {
    width: 100%;
    position: static;
    margin-bottom: 20px;
  }

  .content-area {
    width: 100%;
  }

  .rss-card {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .app-header h1 {
    font-size: 2em;
  }

  .app-header h2 {
    font-size: 1em;
  }

  .header-controls {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }

  .theme-toggle,
  .view-mode-toggle,
  .skin-toggle {
    width: 100%;
  }

  .mixed-content-container {
    flex-direction: column;
  }

  .mixed-content-container .category-nav {
    width: 100%;
    margin-bottom: 20px;
  }

  .mixed-content-container .mixed-content {
    max-width: 100%;
  }

  .mixed-item {
    padding: 15px;
  }

  .skin-selector {
    width: 95%;
    padding: 20px;
  }

  .skin-options {
    grid-template-columns: 1fr;
  }

  .scroll-buttons {
    right: 10px;
    bottom: 10px;
  }

  .scroll-button {
    width: 35px;
    height: 35px;
    font-size: 16px;
  }
}

/* 针对更小的屏幕 */
@media (max-width: 480px) {
  .app-header h1 {
    font-size: 1.75em;
  }

  .app-header h2 {
    font-size: 0.9em;
  }

  .rss-card-header h2 {
    font-size: 1em;
  }

  .rss-card-description {
    font-size: 0.75em;
  }

  .rss-card li {
    font-size: 14px;
  }

  .mixed-item h3 {
    font-size: 1.1em;
  }

  .mixed-item-description {
    font-size: 0.9em;
  }
}

/* 移动端菜单样式 */
.mobile-header-controls {
  display: none;
}

.mobile-menu-toggle {
  background: none;
  border: none;
  font-size: 24px;
  color: var(--primaryColor);
  cursor: pointer;
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--backgroundColor);
  z-index: 1000;
  padding: 20px;
  overflow-y: auto;
}

.mobile-menu-item {
  display: block;
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  background-color: var(--cardBackground);
  color: var(--textColor);
  border: none;
  border-radius: 8px;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
}

.mobile-category-nav {
  margin-top: 20px;
}

.mobile-category-nav button {
  display: block;
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  background-color: var(--cardBackground);
  color: var(--textColor);
  border: none;
  border-radius: 8px;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
}

.mobile-category-nav button.active {
  background-color: var(--primaryColor);
  color: var(--buttonTextColor);
}

/* 响应式设计 */
@media (max-width: 768px) {
  .header-controls {
    display: none;
  }

  .mobile-header-controls {
    display: block;
  }

  .category-nav {
    display: none;
  }

  .content-area {
    width: 100%;
  }

  /* ... 其他移动端样式保持不变 */
}

/* 移动端顶部分类导航 */
.category-nav-mobile {
  position: sticky;
  top: 0;
  background-color: var(--backgroundColor);
  z-index: 100;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding: 10px 0;
  border-bottom: 1px solid var(--borderColor);
}

.category-nav-scroll {
  display: inline-flex;
  padding: 0 10px;
}

.category-nav-mobile button {
  display: inline-block;
  padding: 8px 15px;
  margin-right: 10px;
  background-color: var(--cardBackground);
  color: var(--textColor);
  border: none;
  border-radius: 20px;
  font-size: 14px;
  white-space: nowrap;
}

.category-nav-mobile button.active {
  background-color: var(--primaryColor);
  color: var(--buttonTextColor);
}

/* 底部标签栏 */
.bottom-tab-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--cardBackground);
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  border-top: 1px solid var(--borderColor);
}

.bottom-tab-bar button {
  flex: 1;
  padding: 10px;
  background: none;
  border: none;
  color: var(--textColor);
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom-tab-bar button.active {
  color: var(--primaryColor);
}

/* 调整主内容区域，为底部标签栏留出空间 */
.main-content {
  padding-bottom: 60px;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .app-header {
    padding: 10px 0;
  }

  .app-header h1 {
    font-size: 1.5em;
  }

  .app-header h2 {
    font-size: 0.9em;
  }

  .category-nav {
    display: none; /* 隐藏桌面版的分类导航 */
  }

  .content-area {
    width: 100%;
  }

  .rss-card {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .scroll-buttons {
    bottom: 70px; /* 调整滚动按钮位置，避免被底部标签栏遮挡 */
  }
}

/* ... 其他样式保持不变 ... */

/* 移动设备上的卡片样式 */
.mobile-device .rss-card {
  height: auto; /* 移除固定高度 */
  max-height: none; /* 移除最大高度限制 */
  overflow: visible; /* 允许内容溢出 */
}

.mobile-device .rss-card-content {
  max-height: none; /* 移除内容的最大高度限制 */
  overflow: visible; /* 允许内容溢出 */
}

/* 如果有其他需要在移动设备上调整的样式，可以在这里添加 */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--cardBackground);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.modal-content h2 {
  margin-bottom: 15px;
}

.modal-content form {
  margin-top: 20px;
}

.modal-content input {
  padding: 8px;
  margin-right: 10px;
}

.modal-content button {
  padding: 8px 15px;
  background-color: var(--primaryColor);
  color: var(--buttonTextColor);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.mobile-config-warning {
  padding: 20px;
  background-color: var(--cardBackground);
  color: var(--textColor);
  text-align: center;
  font-size: 18px;
  border-radius: 8px;
  margin-top: 20px;
}

/* ... 其他样式保持不变 */

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: var(--textColor);
}

.config-hint {
  margin-top: 10px;
  font-size: 14px;
  color: var(--textColor);
  opacity: 0.8;
}